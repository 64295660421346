<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <span class="items"><a-input v-model="form.phone" style="width:210px;" class="margin_right20" placeholder="用户手机号" /></span>
                        <span class="items"><a-input v-model="form.phone" style="width:210px;" class="margin_right20" placeholder="推广人手机号" /></span>
                        <span class="items">
                            <a-select ref="select" v-model="form.status" placeholder="是否付费" style="width: 210px" class="margin_right20">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">是</a-select-option>
                                <a-select-option :value="2">否</a-select-option>
                            </a-select>
                        </span>
                        <span class="items">
                            <a-select ref="select" v-model="form.status" placeholder="请选择变更来源" style="width: 210px" class="margin_right20">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">直播-视频号</a-select-option>
                                <a-select-option :value="2">直播-抖音</a-select-option>
                                <a-select-option :value="3">app-ios</a-select-option>
                                <a-select-option :value="4">app-android</a-select-option>
                                <a-select-option :value="5">推广大使二维码</a-select-option>
                                <a-select-option :value="6">阅读大使二维码</a-select-option>
                                <a-select-option :value="7">兑换码</a-select-option>
                                <a-select-option :value="8">人工操作</a-select-option>
                            </a-select>
                        </span>
                        <span class="items">
                            <a-select ref="select" v-model="form.status" placeholder="请选择变更类型" style="width: 210px" class="margin_right20">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">新入会</a-select-option>
                                <a-select-option :value="2">会期内续费</a-select-option>
                                <a-select-option :value="3">会期过期转化</a-select-option>
                                <a-select-option :value="4">会期延期</a-select-option>
                                <a-select-option :value="5">新课程付费</a-select-option>
                                <a-select-option :value="6">退款</a-select-option>
                                <a-select-option :value="7">回收权益</a-select-option>
                            </a-select>
                        </span>
                        <span class="items"><a-range-picker @change="onChangeDate" style="width: 210px;" class="margin_right20"/></span>
                        <a-button type="primary" class="margin_right20">查询</a-button>
                        <a-button type="primary">重置</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.id"  :loading="tableLoading" :pagination="false">
                        <span slot="number" slot-scope="number, record, index">
                            <span>{{ index + 1 }}</span>
                        </span>
                        <img v-viewer class="avatar" slot="imageUrl" slot-scope="imageUrl, record" :src="record.imageUrl" alt="头像"/>
                        <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
                            <a-badge v-show="record.putawayFlag == 0" status="error" text="已下架"/>
                            <a-badge v-show="record.putawayFlag == 1" status="processing" text="定时上架"/>
                            <a-badge v-show="record.putawayFlag == 2" status="success" text="已上架"/>
                            <a-badge v-show="record.putawayFlag == 3" status="warning" text="暂不上架"/>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <template>
                                <a class="btn-edit" @click="onEdit(record)">详情</a>
                            </template>
                        </span>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {Modal, message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import {GetBannerApi} from "@/request/api/indexManage";

export default {
    components: {MyPagination},
    created() {
        if (codeFn("addCategory")) this.isShowAdd = true;
        if (codeFn("/admin/course/label/updLabel")) this.isShowUpd = true;
        if (codeFn("/admin/course/label/delLabel")) this.isShowDel = true;
    },
    data() {
        return {
            name:'',
            spinning: false, // 全局loading
            tableLoading: false,

            form:{
                value:[],
                phone:'',
                status: undefined,
            },

            columns: [
                {
                    title: "用户姓名",
                    dataIndex: "number",
                    key: "number",
                },
                {
                    title: "用户手机",
                    dataIndex: "imageUrl",
                    key: "imageUrl",
                },
                {
                    title: "变更来源",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "变更描述",
                    dataIndex: "putawayFlagText",
                    key: "putawayFlagText",
                },
                {
                    title: "变更前渠道",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "变更后渠道",
                    dataIndex: "createTime1",
                    key: "createTime1",
                },
                {
                    title: "变更时间",
                    dataIndex: "createTime2",
                    key: "createTime2",
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "80px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数

            options: [ {
                id: 'a',
                label: 'a',
                children: [ {
                    id: 'aa',
                    label: 'aa',
                }, {
                    id: 'ab',
                    label: 'ab',
                } ],
            }, {
                id: 'b',
                label: 'b',
            }, {
                id: 'c',
                label: 'c',
            } ],

            isShowAdd: false, // 修改是否隐藏
            isShowUpd: false, // 新增是否隐藏
            isShowDel: false, // 删除是否隐藏
        };
    },
    mounted() {
        this.getTagList();
    },
    methods: {
        treeselectChange(value, instanceId){
            console.log(value)
        },

        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0];
                this.form.endTime = dateString[1];
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.courseInfoFn();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            GetBannerApi({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.data;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },

        // 编辑
        onEdit(record) {
            this.$router.push(`/userManage/legalRightList/legalRightDetails/${record.id}`);
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
    flex-wrap: wrap;
}
.items{
    margin-bottom: 15px;
}
</style>
